.text {
    font-family: 'Quicksand', sans-serif;
    max-width: 800px;
    margin: auto;
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 30px;
}

ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
  }
  
  ol > li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
  }
  
  ol > li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 0.6em;
    font-weight: bold;
  }
  
  li ol > li {
    margin: 0;
  }
  
  li ol > li:before {
    content: counters(item, ".") " ";
    font-weight: normal;
  }